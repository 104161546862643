import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Card, Col, Row, Skeleton } from 'antd';
import { Asset } from 'shared-types';

import Box from 'components/Box/Box';

import useAssetsStore from 'hooks/stores/useAssetsStore';
import useSessionStore from 'hooks/stores/useSessionStore';
import useAutomateUserWorkflow from 'hooks/useAutomateUserWorkflow';

import API from 'services/API';

import AssetsAndSidesActions from './AssetsAndSidesActions';
import AssetsPreviews from './AssetsPeviews';
import Instructions from './Instructions';
import RealTimeAssetsProcessing from './RealTimeAssetsProcessing';

type Props = {
  showInstructions?: boolean;
  showAddBlankPage?: boolean;
  shouldFetchAssets?: boolean;
  autoScrollDependency?: any;
  children?: React.ReactNode;
};

const Assets: FC<Props> = ({
  showInstructions = true,
  showAddBlankPage = false,
  shouldFetchAssets = true,
  autoScrollDependency,
  children,
}) => {
  const sessionId = useSessionStore((state) => state.sessionId);
  const setAssets = useAssetsStore((state) => state.setAssets);
  const rowRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const {
    data: assets,
    isLoading: isLoadingAssets,
    isRefetching,
    isError,
    error,
  } = useQuery<Asset[]>('assets', () => API.getAssets(sessionId), {
    enabled: !!sessionId && shouldFetchAssets,
    onSuccess: (data) => {
      setAssets(data);
    },
  });

  const { automateUserWorkflowInSingleSide, shouldSkipAutomateWorkflow } =
    useAutomateUserWorkflow();

  useEffect(() => {
    if (shouldSkipAutomateWorkflow) return;
    automateUserWorkflowInSingleSide();
  }, [automateUserWorkflowInSingleSide, shouldSkipAutomateWorkflow]);

  // // auto scroll to bottom
  useEffect(
    function autoScrollToBottom() {
      if (rowRef.current) {
        console.log(
          'scrolling to bottom',
          rowRef.current.scrollHeight,
          rowRef.current.clientHeight,
        );
        rowRef.current.scrollTo({
          top: rowRef.current.scrollHeight + rowRef.current.clientHeight,
          behavior: 'smooth',
        });
      }
    },
    [autoScrollDependency],
  );

  if (isError || error) return <Box>{t('ErrorFetchingAssets')}</Box>;
  if (isLoadingAssets) return <Skeleton active />;

  return (
    <>
      <Card style={{ maxHeight: '100vh' }}>
        <RealTimeAssetsProcessing />
        <AssetsAndSidesActions showAddBlankPage={showAddBlankPage} />
        <Row
          gutter={8}
          style={{ marginTop: '1rem', overflowY: 'auto', maxHeight: 'calc(100vh - 350px)' }}
          ref={rowRef}
        >
          {isRefetching && (
            <Col className="gutter-row" span={12}>
              <Skeleton.Image active style={{ height: '124px', width: '90px' }} />
            </Col>
          )}
          {children || <AssetsPreviews assets={assets} />}
        </Row>
        {showInstructions && <Instructions />}
      </Card>
    </>
  );
};

export default Assets;
