import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import ClipLoader from 'react-spinners/ClipLoader';

// Add types to this code
type Props = {
  file?: Blob;
  loading: boolean;
  isFullPreview: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  pageWidth: number;
  onImageGenerated?: (imageUrl: string) => void;
};

const FilePreview: FC<Props> = ({
  file,
  loading,
  isFullPreview,
  pageWidth = 500,
  onClick,
  onImageGenerated,
}) => {
  const ref = useRef(null);
  const isLoadingFile = !file || loading;
  const [width, setWidth] = useState(pageWidth);
  const scale = 0.7; // adjust this as needed

  const handleRenderSuccess = (page: any) => {
    try {
      // We typeren het element specifiek als een HTMLDivElement omdat we weten dat _container een div is
      const canvasElements = document.getElementsByTagName('canvas');
      // Let's log what we find to help debug

      // We want the most recently added canvas, which should be our PDF page
      const canvas = canvasElements[canvasElements.length - 1];

      if (!canvas) {
        throw new Error('No canvas element found in the document');
      }
      // Nu TypeScript weet dat canvas een HTMLCanvasElement is,
      // zal toDataURL() correct herkend worden als een geldige methode
      const imageUrl = canvas.toDataURL('image/png');

      onImageGenerated?.(imageUrl);
    } catch (error) {
      console.error('Error converting PDF to image:', error);
    }
  };

  useEffect(() => {
    /**
     * Handles the resize event and adjusts the width of the component accordingly.
     */
    const handleResize = () => {
      let drawWidth = window.innerWidth;
      let drawHeight = window.innerHeight;
      let width = drawWidth;
      let height = width / scale;

      if (width > drawWidth || height > drawHeight) {
        height = drawHeight;
        width = height * scale;
      }

      setWidth(width);
    };

    // Initial resize
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up function
    return () => {
      // Remove event listener
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
      ref={ref}
      onClick={onClick}
    >
      {isLoadingFile ? (
        <ClipLoader color="#35b3ee" />
      ) : isFullPreview ? (
        <Document file={file} loading={<ClipLoader color="#35b3ee" />}>
          <Page pageNumber={1} width={width} scale={scale} onRenderSuccess={handleRenderSuccess} />
        </Document>
      ) : (
        <Document file={file} loading={<ClipLoader color="#35b3ee" />}>
          <Page pageNumber={1} width={pageWidth} />
        </Document>
      )}
    </div>
  );
};

export default FilePreview;
