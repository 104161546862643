import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
// import { MutateOptions } from 'react-query';
import { Card, Flex } from 'antd';
import { equals } from 'ramda';
import { ActionsXorCropArea, RotationDirection, SideWithAsset } from 'shared-types';
import styled from 'styled-components';

import Box from 'components/Box/Box';
import { Area } from 'components/EasyCrop/types';
import FilePreview from 'components/ProductSide/FilePreview';
import Toolbox from 'components/ProductSide/Toolbox';

import useSessionStore from 'hooks/stores/useSessionStore';
import useActions from 'hooks/useActions';
import useCrop from 'hooks/useCrop';
import useDownloadPDF from 'hooks/useDownloadPDF';
import useEditor from 'hooks/useEditor';

import API from 'services/API';

import { calculateCropToIncludeBleed, createCropArea, getSizeInPixel } from 'utils/pdf';

import CropModal from './CropModal';

export const SCard = styled(Card)`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1rem;
  height: 100%;
`;

export const SDelete = styled(Box)`
  display: flex;
  justify-content: center;
`;

type Props = {
  side: SideWithAsset;
  onPdfPreviewGenerated: (previewUrl: string) => void;
};

const PageCardEditor = ({ side, onPdfPreviewGenerated }: Props) => {
  const isLoadingRef = useRef(true);
  const { bleed, productSize } = useSessionStore((state) => ({
    bleed: state.session?.bleed,
    productSize: state.session?.productSize,
  }));
  const [pdfPreview, setPdfPreview] = useState<string | null>(null);
  const sessionId = useSessionStore((state) => state.sessionId);
  const queryClient = useQueryClient();
  const [isOpened, setIsOpened] = useState(false);

  // Gebruik ref voor rotatie state
  const isRotatingRef = useRef(false);
  const isHandlingRotation = useRef(false);

  const { crop, zoom, onCropChange, onZoomChange, onCropComplete, croppedArea, resetZoom } =
    useCrop();
  const [capturedArea, setCapturedArea] = useState<Area | null>(null);

  const { actions, id: sideId, productId, asset, cropArea } = side;
  const { pdfFile: originalPdf, isDownloading } = useDownloadPDF({ fileUrl: asset.previewFile });
  const { newActions, setActions, handleFlip } = useActions(actions);

  const isUserActionRef = useRef(false);

  const { mutate } = useMutation(
    `updateSide-${sideId}`,
    (actionsOrCropArea: ActionsXorCropArea) => API.updateSide(sessionId, sideId, actionsOrCropArea),
    {
      onSuccess: (data) => {
        console.log('SETACTIONS', data.actions);
        //setActions(data.actions);

        queryClient.invalidateQueries('products');
      },
    },
  );

  const handleRotate = useCallback(
    (direction: RotationDirection) => {
      if (isRotatingRef.current) return;
      isRotatingRef.current = true;
      isUserActionRef.current = true;
      const newRotation = Math.floor(
        (((actions.rotation + (direction === 'right' ? 90 : -90)) % 360) + 360) % 360,
      );

      const updatedActions = {
        ...actions,
        rotation: newRotation,
      };

      mutate(
        { actions: updatedActions },
        {
          onError: () => {
            // Rollback bij error
            setActions(actions);
          },
          onSuccess: () => {
            isRotatingRef.current = false;
          },
          onSettled: () => {
            isRotatingRef.current = false;
          },
        },
      );
    },
    [actions, mutate, setActions],
  );

  // const handleRotate = useCallback(
  //   (direction: RotationDirection) => {
  //     if (isRotatingRef.current) return;
  //     isRotatingRef.current = true;

  //     const newRotation = Math.floor(
  //       (((actions.rotation + (direction === 'right' ? 90 : -90)) % 360) + 360) % 360,
  //     );

  //     const updatedActions = {
  //       ...actions,
  //       rotation: newRotation,
  //     };

  //     // Optimistische update
  //     //setActions(updatedActions);

  //     mutate(
  //       { actions: updatedActions },
  //       {
  //         onError: () => {
  //           // Rollback bij error
  //           setActions(actions);
  //         },
  //         onSuccess: () => {
  //           isRotatingRef.current = false;
  //         },
  //         onSettled: () => {
  //           isRotatingRef.current = false;
  //         }
  //       }
  //     );
  //   },
  //   [actions, mutate, setActions]
  // );

  const aspectRatio = Number(productSize?.width) / Number(productSize?.height);
  let assetHasTrimbox = false;
  if (asset.dimensions && asset.metadata?.trimbox) {
    if (
      Math.abs(asset.dimensions.width - asset.metadata.trimbox.width) > 0.01 ||
      Math.abs(asset.dimensions.height - asset.metadata.trimbox.height) > 0.01 ||
      asset.metadata.trimbox.x > 0 ||
      asset.metadata.trimbox.y > 0
    ) {
      assetHasTrimbox = true;
    }
  }

  const generatedPdf = useEditor({
    pdfFile: originalPdf,
    newActions,
    metaData: asset.metadata,
    dimensions: asset.dimensions,
    cropArea: cropArea || capturedArea,
    asset,
  });

  // when flag is set to true or rotation is bigger than 0 disable crop
  const disableCrop =
    newActions.flip || newActions.rotation !== 0 || actions.flip || actions.rotation !== 0;

  const handleOpenModal = () => {
    setIsOpened(true);
    resetZoom();
    setCapturedArea(null);
  };

  const handleCloseModal = () => {
    setIsOpened(false);
    setCapturedArea(null);
  };

  const normalizeCropArea = (cropArea: any) => {
    console.log('original cropArea', cropArea, 100 - cropArea?.height - cropArea?.y || 0);
    return {
      x: Number(Number(cropArea?.x || 0).toFixed(10)),
      y: Number(Number(100 - cropArea?.height - cropArea?.y || 0).toFixed(10)),
      width: Number(Number(cropArea?.width || 100).toFixed(10)),
      height: Number(Number(cropArea?.height || 100).toFixed(10)),
    };
  };

  // on model close set captured area to cropped area
  const handleSaveAndCloseModal = () => {
    setIsOpened(false);
    isUserActionRef.current = true;
    // normalize y value to use left bottom as 0
    const area = normalizeCropArea(croppedArea);
    setCapturedArea(area || null);

    console.log('croppedArea', area);
  };

  useEffect(() => {
    isUserActionRef.current = false;
    setCapturedArea(cropArea);
  }, [sideId, cropArea]);

  useEffect(() => {
    if (!isUserActionRef.current) {
      return;
    }

    if (capturedArea && !equals(capturedArea, cropArea) && !isHandlingRotation.current) {
      mutate({ cropArea: capturedArea });
    }
  }, [capturedArea, cropArea, mutate, sideId]);

  useEffect(() => {
    setActions(newActions);
  }, [newActions]);

  const handleZoomOut = () => {
    if (!bleed || !productSize) return;
    isUserActionRef.current = true;
    const assetTrimbox = asset.dimensions.trimbox;
    const productTrimbox = {
      x: 0,
      y: 0,
      width: getSizeInPixel(Number(productSize.width) + Number(bleed.left) + Number(bleed.right)),
      height: getSizeInPixel(Number(productSize.height) + Number(bleed.top) + Number(bleed.bottom)),
    };
    const crop = calculateCropToIncludeBleed(assetTrimbox, productSize, bleed);
    setCapturedArea(crop);
  };

  const handleLocalFlip = () => {
    isUserActionRef.current = true;
    //    return handleFlip();
    const updatedActions = {
      ...actions,
      flip: !actions.flip,
    };
    mutate(
      { actions: updatedActions },
      {
        onError: () => {
          // Rollback bij error
          setActions(actions);
        },
        onSuccess: () => {
          isRotatingRef.current = false;
        },
        onSettled: () => {
          isRotatingRef.current = false;
        },
      },
    );
  };

  const handleSetOriginal = () => {
    if (!bleed || !productSize) return;
    isUserActionRef.current = true;
    const assetTrimbox = asset.dimensions.trimbox;
    const productTrimbox = {
      x: getSizeInPixel(Number(bleed.left || 0)),
      y: getSizeInPixel(Number(bleed.top || 0)),
      width: getSizeInPixel(Number(productSize.width)),
      height: getSizeInPixel(Number(productSize.height)),
    };
    const crop = createCropArea(assetTrimbox, productTrimbox);
    resetZoom();
    // onCropChange({
    //   x: crop.x,
    //   y: crop.y
    // });
    setCapturedArea(crop);
  };

  const processGeneratedImage = (imageUrl: string) => {
    onPdfPreviewGenerated(imageUrl);
  };

  return (
    <Card key={side.id} style={{ padding: 0 }}>
      <Flex
        key={side.id}
        justify="flex-start"
        align="center"
        style={{ width: '100%', marginBottom: '0.5rem', height: '80vh', overflow: 'auto' }}
        vertical
      >
        <Toolbox
          onRotate={(direction) => handleRotate(direction)}
          onFlip={handleLocalFlip}
          onZoomOut={handleZoomOut}
          onSetOriginal={handleSetOriginal}
          onCrop={handleOpenModal}
          productId={productId}
          sideId={sideId}
          showActions={['rotate', 'flip', 'crop', 'zoomout', 'reset']}
          disableActions={disableCrop ? ['crop'] : []}
        />
        <FilePreview
          file={generatedPdf}
          loading={isDownloading}
          isFullPreview={true}
          pageWidth={Number(productSize?.width) || 400}
          onImageGenerated={processGeneratedImage}
        />
      </Flex>
      {isOpened && (
        <CropModal
          isOpen={isOpened}
          onClose={handleCloseModal}
          onSave={handleSaveAndCloseModal}
          sessionId={sessionId}
          sideId={sideId}
          crop={crop}
          zoom={zoom}
          aspectRatio={aspectRatio}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={onCropComplete}
        />
      )}
    </Card>
  );

  isLoadingRef.current = false;
};
export default PageCardEditor;
